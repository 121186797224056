import React from 'react';

import { Layout } from '@/components/Layout';
import { Box, Flex, Heading, Link, Button } from 'rebass/styled-components';
import { Text } from '@/components/Text';
import { jobs } from '@/cms/jobs';
import { styledTheme } from '@/styledTheme';
import { ContentWrapper } from '@/components/ContentWrapper';
import { underlineLinks } from '@/utils/underlineLinks';

export default () => (
  <Layout>
    <Box pt={['95px', '95px', '55px']} mb={[0, 0, '140px']}>
      <ContentWrapper maxWidth={1000} mb={[3, 3, 0]}>
        <Heading
          fontSize={[5, 5, 6]}
          mb={3}
          fontWeight={700}
          fontFamily={styledTheme.fonts.body}
        >
          Join us as a Software Engineer!
        </Heading>
        <Text mb={3}>
          We are looking for a software engineer o join our team and help us
          build web applications in a delightful atmosphere. We usually build
          applications using our favourite stack, which consists of React on the
          frontend with an Apollo GraphQL server running on the backend. We use
          codegen which makes our work with TypeScript a pleasant experience.
        </Text>
        <Text mb={3}>
          We work remotely, asynchronously, pair whenever needed, organize our
          work on Kanban boards, use Figma designs and communicate on Slack.
        </Text>
        <Text mb={3}>
          The focus of this position would be mainly on the frontend, but you
          will be more than welcome to work on the backend Node applications.
        </Text>
        <Text mb={3}>
          This position requires advanced working knowledge of: <br />
          - React <br />
          - TypeScript <br />- Jest
        </Text>
        <Text mb={3}>
          Not strictly required, but very welcome: <br />
          - GraphQL <br />
          - Styled components <br />
          - Apollo <br />
          - Gatsby <br />
          - Cypress <br />
        </Text>
        <Text mb={3}>
          Expected workload: <br />- 15-20h weekly, possibly full-time soon
        </Text>
        <Text mb={3} css={underlineLinks()}>
          Please send your profile to{' '}
          <Link href="mailto:hello@skalar.studio?subject=I want to work with you!">
            <b>hello@skalar.studio</b>
          </Link>
          . You will get bonus points for recording a short video and telling us
          a few words about yourself and what you like to work on.
        </Text>
      </ContentWrapper>
    </Box>
  </Layout>
);
